import { computed, reactive, ref } from "vue";
import { useRoute, useRouter } from "vue-router";
import activityService from "@/services/ActivityService";
import { usePictureStore } from "@/store/pictureStore";
import { useAreaStore } from "@/store/areaStore";
import { useAuthStore } from "@/store/authStore";
import { useIndexedDB } from "@/composables/useIndexedDB";
import axios from "@/config/axios";
import moment from "moment-timezone";

export function useActivities() {
  const pictureStore = usePictureStore();
  const areaStore = useAreaStore();
  const authStore = useAuthStore();
  const useIndexed = useIndexedDB();
  const router = useRouter();
  const route = useRoute();
  const message = ref("");
  const loading = ref(false);
  const form = reactive({
    activity_type: null,
    activity_type_id: null,
    staff_id: null,
    area_id: null,
    building_id: null,
    story_id: null,
    company_id: null,
    parent_id: null,
    comentarios: "",
    observaciones: "",
    isComment: false,
    status: "s",
    before: [],
    after: [],
  });

  const finish = computed(() => route.path === "/fin");
  const needPictures = computed(() => ["/fin", "/inicio"].includes(route.path));
  const comments = computed(() => route.path === "/comments");
  const isTypeCleaning = computed(() => route.path === "/tipo_limpieza");
  const textButton = computed(() => {
    let text = "Cerrar área";
    if (!loading.value) {
      if (isTypeCleaning.value) text = "Siguiente";
      if (route.path === "/inicio") text = "Empezar Tarea";
      if (comments.value) text = "Continuar";
    } else {
      text = "Cargando...";
    }
    return text;
  });
  const titlePicture = computed(() => {
    if (route.path === "/inicio") {
      return "Captura el estado actual antes de limpiar";
    } else if (route.path === "/comments") {
      return "Observaciones y comentarios finales"; // Cambio para la vista /comments
    } else {
      return "Captura el estado actual después de limpiar";
    }
  });
  /*const hasImage = computed(() => {
    let resp = true;
    let routes = ["/inicio", "/fin"];
    if (!pictureStore.pictures.length && routes.includes(route.path)) {
      message.value = "* Necesita tomar al menos una foto";
      resp = false;
    }
    return resp;
  });*/

  async function createActivity() {
    //if (!hasImage.value) return;
    if (isTypeCleaning.value && !form.activity_type !== "") {
      router.push({ name: "start" });
      return;
    }
    loading.value = true;
    await autocompleteForm();
    await saveActivityIndexedDB();
    await changeView();
    if (navigator.onLine && route.path === "/comments") {
      await sendActivityLocal();
      localStorage.removeItem("type_cleaning");
    }
    await resetForm();
  }
  async function saveActivity(form) {
    let { data } = await activityService.storeActivity(form);
    localStorage.setItem("parentId", data.data.id);
  }

  function resetForm() {
    form.$reset;
    form.activity_type = localStorage.getItem("type_cleaning") || null;
    pictureStore.resetPictures();
    message.value = "";
    loading.value = false;
  }

  function autocompleteForm() {
    form.staff_id = authStore.staff_id;
    form.area_id = areaStore.id_area;
    form.story_id = areaStore.area.story_id;
    form.building_id = areaStore.area.building_id;
    form.company_id = areaStore.area.company_id;
    areaStore.activity_type_id = form.activity_type_id;
    if (!finish.value && !comments.value) {
      form.status = "s";
      form.comentarios = `Inicio de la limpieza del area ${areaStore.area.name} del edificio ${areaStore.building} en el piso ${areaStore.floor} del cliente ${areaStore.customer}`;
      form.observaciones = `Inicio de la limpieza del area ${areaStore.area.name} del edificio ${areaStore.building} en el piso ${areaStore.floor} del cliente ${areaStore.customer}`;
    }
    if (comments.value) {
      form.status = "c";
      form.parent_id = localStorage.getItem("parentId");
      form.isComment = true;
    }
    if (finish.value) {
      form.status = "c";
      form.parent_id = localStorage.getItem("parentId");
    }
  }
  function getMedia(media) {
    finish.value ? form.after.push(media) : form.before.push(media);
  }

  async function sendActivityLocal() {
    let activities = await useIndexed.getInfo("activities");
    let images = await useIndexed.getImages();
    let area_ids = [...new Set(activities.map((item) => item.area_id))];
    let dataFilter = area_ids.map((id) => {
      let activities = images.filter((item) => item.area_id === id);
      return { [id]: activities };
    });

    for (const item of dataFilter) {
      const area_id = Object.keys(item)[0];
      const images = item[area_id];
      await sendActivityStart(images, parseInt(area_id));
      await sendActivityEnd(images, parseInt(area_id));
    }
  }

  async function sendActivityStart(images, area_ids) {
    let activities = await useIndexed.searchDatas(
      "activities",
      area_ids,
      "idxAreaId"
    );
    let activities_start = activities.find((item) => item.status === "s");
    let photo_before = images.filter((rsp) => rsp.isBefore);
    const ImagesPromise = photo_before.map((item) => {
      return new Promise((resolve, reject) => {
        let formData = convertImage(item);
        activityService
          .saveMedia(formData)
          .then(({ data }) => {
            activities_start.before.push(data);
            useIndexed.deleteData("images", item.id);
            resolve();
          })
          .catch((error) => {
            reject(error);
          });
      });
    });
    await Promise.all(ImagesPromise);
    await saveActivity(activities_start).then(() => {
      useIndexed.deleteData("activities", activities_start.id);
    });
  }

  async function sendActivityEnd(images, area_ids) {
    let activities = await useIndexed.searchDatas(
      "activities",
      area_ids[0],
      "idxAreaId"
    );
    let activities_end = activities.find((item) => item.status === "c");
    let photo_after = images.filter((rsp) => !rsp.isBefore);
    const ImagesPromise = photo_after.map((item) => {
      return new Promise((resolve, reject) => {
        let formData = convertImage(item);
        activityService
          .saveMedia(formData)
          .then(({ data }) => {
            activities_end.after.push(data);
            useIndexed.deleteData("images", item.id);
            resolve();
          })
          .catch((error) => {
            reject(error);
          });
      });
    });
    activities_end.parent_id = localStorage.getItem("parentId") || null;
    await Promise.all(ImagesPromise);
    await saveActivity(activities_end).then(() => {
      useIndexed.deleteData("activities", activities_end.id);
      localStorage.removeItem("parentId");
    });
  }

  function convertImage(item) {
    let file = new File([item.image], "picture_register_activity", {
      type: "image/png",
    });
    let formData = new FormData();
    let collectionName = item.isBefore ? "activity_before" : "activity_after";
    formData.append("file", file);
    formData.append("collection_name", collectionName);
    return formData;
  }

  async function saveActivityIndexedDB() {
    let activityTypeLocal = localStorage.getItem("type_cleaning");
    let defaultActivityType = form.activity_type;
    if (!form.isComment) {
      let payload = {
        ...form,
        parent_local_id: localStorage.getItem("local:activity_id") || null,
        before: [],
        after: [],
        created_at: getDateTime(),
        activity_type: activityTypeLocal || defaultActivityType,
      };
      await useIndexed.insertData("activities", payload).then((id) => {
        localStorage.setItem("local:activity_id", id);
      });
    } else {
      useIndexed
        .searchData(
          "activities",
          parseInt(localStorage.getItem("local:activity_id"))
        )
        .then((rsp) => {
          let payload = {
            ...rsp,
            comentarios: form.comentarios,
            observaciones: form.observaciones,
          };
          useIndexed.updateData("activities", {
            ...payload,
            id: parseInt(localStorage.getItem("local:activity_id")),
          });
          localStorage.removeItem("local:activity_id");
        });
    }
  }

  function changeView() {
    if (finish.value) {
      router.push({ name: "comments" });
      form.observaciones = "";
      form.comentarios = "";
    } else if (comments.value) {
      router.push({ name: "next" });
      areaStore.$reset;
      localStorage.removeItem("area:current");
      localStorage.removeItem("parentId");
    } else {
      router.push({ name: "end" });
    }
  }

  async function activityFinishWorkday() {
    let payload = {
      staff_id: authStore.staff_id,
      status: "fw",
      observaciones: `Fin Jornada Laboral ${authStore.name}`,
      building_id: localStorage.getItem("building:current"),
      parent_id: localStorage.getItem("workday:open"),
      created_at: getDateTime(),
    };
    if (navigator.onLine) {
      await activityService.storeActivity(payload);
      await sendActivityLocal();
      await logout();
      await authStore.deleteUserIndexedDB();
    } else {
      await useIndexed.insertData("activities", payload);
      await authStore.logout();
    }
  }

  async function sendActivityFinishWorkday() {
    let resp = await useIndexed.searchDatas("activities", "fw", "idxStatus");
    if (resp.length) {
      for (const item of resp) {
        await activityService.storeActivity(item);
        await useIndexed.deleteData("activities", item.id);
      }
      await authStore.deleteUserIndexedDB();
    }
  }

  async function logout() {
    await axios.post("logout").catch((e) => {
      console.error(e);
      router.push({ name: "finisOk" });
    });
    await authStore.logout();
  }

  function getDateTime() {
    let date_serve = moment(localStorage.getItem("dateTime_serve"), "hh:mm A");
    let date_device = moment(
      localStorage.getItem("dateTime_device"),
      "hh:mm A"
    );

    let duration = moment.duration(date_device.diff(date_serve)).asMinutes();
    let datetime_current = moment().format("YYYY-MM-DD HH:mm:ss");
    let createdAt = moment(datetime_current)
      .add(duration, "minute")
      .format("YYYY-MM-DD HH:mm:ss");
    return createdAt;
  }

  return {
    form,
    textButton,
    titlePicture,
    finish,
    comments,
    getMedia,
    createActivity,
    saveActivity,
    message,
    loading,
    activityFinishWorkday,
    sendActivityFinishWorkday,
    sendActivityLocal,
    needPictures,
    isTypeCleaning,
    logout,
  };
}
